import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "gatsby-image";
import { ContactQuery } from "../../graphql-types";
import styled from "styled-components";

export const pageQuery = graphql`
  query Contact {
    githubLogo: file(relativePath: { eq: "githubLogo.png" }) {
      childImageSharp {
        fixed(height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    linkedinLogo: file(relativePath: { eq: "linkedinLogo.png" }) {
      childImageSharp {
        fixed(height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const Page = () => {
  const data = useStaticQuery(pageQuery) as ContactQuery;
  return (
    <Layout>
      <SEO title="contact" />
      <Logos>
        <h1>Do you want to know more about me?</h1>
        <a href="https://github.com/EvaTomlinson" target="_blank">
          <Img fixed={data.githubLogo.childImageSharp.fixed} />
        </a>
        <a
          href="https://www.linkedin.com/in/eva-tomlinson-62a2261b9/"
          target="_blank"
        >
          <Img fixed={data.linkedinLogo.childImageSharp.fixed} />
        </a>
      </Logos>
    </Layout>
  );
};

const Logos = styled.div`
  font-size: 50px;
  line-height: normal;
`;

export default Page;
